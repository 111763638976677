/*====================
    Info Area 
======================*/

.info-area {

    div.info {

        a {
            @extend %transition;
            @extend .im-hover;
            color: var(--color-gray);
            font-size: 16px;
            padding: 5px 0;
            &:hover {
                color: var(--color-primary);
            }
        }

        div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-align: baseline;
            -ms-flex-align: baseline;
            align-items: baseline;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin: 0 0 15px 0;
            @media #{$sm-layout} {
                display: block;
                margin: 0 0 40px 0;
            }
        }

        .date {
            margin: 0 0 0 0;
            position: relative;
            z-index: 1;
        }

        .title {
            width: 80%;
            @media #{$sm-layout} {
                width: 100%;
            }
            position: relative;
        }

        p:last-child {
            margin-bottom: 0;
        }

    }
}